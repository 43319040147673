import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { PrimaryButton } from '../../components';
import css from './ListingPage.module.css';


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}));

const ModalValidationPending = (props) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  if (!props.open) {
    return null
  }

  return <Modal
    open={props.open}
    onClose={props.handleClose}
    aria-labelledby="confirm-modal-title"
    aria-describedby="simple-modal-description">
    <div style={{
      top: `50%`,
      left: `50%`,
      transform: `translate(-50%, -50%)`
    }} className={css.paper}>
      <h2>Annonce créée avec succès</h2>
      <p>Toutes les annonces sur jardins-prives.com sont vérifiées avant d'être publiées sur notre site. Notre service la validera dans les 48h et une fois que votre annonce aura été approuvée par notre équipe, un email vous sera envoyé et votre annonce sera publiée.</p>
      <p></p>
      <PrimaryButton style={{ margin: '0 auto' }} onClick={props.handleClose}>
        Ok
      </PrimaryButton>
    </div>
  </Modal>
}

export default ModalValidationPending; 