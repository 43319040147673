import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import {
  NamedLink,
} from '../../components';
import css from './ListingPage.module.css';

const SectionHeading = props => {
  const {
    richTitle,
    category,
    currentListing
  } = props;


  return (
    <div className={css.sectionHeading}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
        <div className={css.mobileViewLinkProfil}>
          <NamedLink name="ProfilePage" params={{id: currentListing.author.id.uuid}}>
              Voir Profil - {currentListing.author.attributes.profile.displayName}
          </NamedLink>
        </div>
        <div className={css.author}>
          <FormattedMessage id={`EditListingCategoryForm.${category}`} />
        </div>
      </div>
    </div>
  );
};

export default SectionHeading;
