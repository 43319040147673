import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { ResponsiveImage, Modal, ImageCarousel, NamedLink } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';
import css from './ListingPage.module.css';
import DefaultImage from '../../assets/default-image.jpg'
import {
  createSlug,
} from '../../util/urlHelpers';
import { Grid } from '@material-ui/core';

const setImages = (listing) =>  {

  if(!listing) return null
  const imgs = listing.images;
  let photos = []
  for(let  i = 0 ; i < imgs.length ; i++){
    photos.push({
           img: imgs[i] && imgs[i].attributes &&
              imgs[i].attributes.variants &&
              imgs[i].attributes.variants['landscape-crop4x'] &&
              imgs[i].attributes.variants['landscape-crop4x'].url,
           alt: `images-${i}`
    })
  }
  return  photos;
}


const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const nImages = hasImages ? listing.images.length : 0
  const firstImage = hasImages ? listing.images[0] : null;
  const slug = createSlug(listing.attributes.title);

  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  const images = setImages(listing) || []

  if(!images || images.length === 0){
    return <div className={css.noImages}>
        <p>N'oubliez pas d'ajouter des photos a votre annonce !</p>
        <NamedLink
            name="EditListingPage"
            params={{
              id: listing.id.uuid,
              slug: slug,
              type: "edit",
              tab: 'photos',
              category: listing.attributes.publicData.category }}
          >
            Ajouter des photos
          </NamedLink>
      </div>
  }

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div  onClick={handleViewPhotosClick} className={css.desktopView}>
          {
            images.length > 1 ? (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} style={{maxHeight: 500}}>
                  <div className={css.imgContainer}>
                    <img src={images[0].img} alt={images[0].alt}
                      className={css.imgGallery}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid container spacing={1}>
                    {
                      nImages === 2 ?
                      <>
                      <Grid item xs={ 12} style={{maxHeight: 500}}>
                      <div className={css.imgContainer}>
                        <img src={images[1].img} alt={images[1].alt}
                          className={css.imgGallery}
                        />
                      </div>
                      </Grid>
                      </>
                      : null
                    }
                    {
                      nImages === 3 ?
                      <>
                      <Grid item xs={ 12} style={{maxHeight: 250}}>
                      <div className={css.imgContainer}>
                        <img src={images[1].img} alt={images[1].alt}
                          className={css.imgGallery}
                        />
                      </div>
                      </Grid>
                      <Grid item xs={ 12} style={{maxHeight: 250}}>
                        <div className={css.imgContainer}>
                          <img src={images[2].img} alt={images[2].alt}
                            className={css.imgGallery}
                          />
                        </div>
                      </Grid>
                      </>
                      : null
                    }
                    {
                      nImages === 4 ?
                      <>
                      <Grid item xs={ 6} style={{maxHeight: 250}}>
                      <div className={css.imgContainer}>
                        <img src={images[1].img} alt={images[1].alt}
                          className={css.imgGallery}
                        />
                      </div>
                      </Grid>
                      <Grid item xs={ 6} style={{maxHeight: 250}}>
                        <div className={css.imgContainer}>
                          <img src={images[2].img} alt={images[2].alt}
                            className={css.imgGallery}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} style={{maxHeight: 250}}>
                        <div className={css.imgContainer}>
                          <img src={images[3].img} alt={images[3].alt}
                            className={css.imgGallery}
                          />
                        </div>
                      </Grid>
                      </>
                      : null
                    }
                    {
                      nImages >= 5 ?
                      <>
                      <Grid item xs={ 6} style={{maxHeight: 250}}>
                      <div className={css.imgContainer}>
                        <img src={images[1].img} alt={images[1].alt}
                          className={css.imgGallery}
                        />
                      </div>
                      </Grid>
                      <Grid item xs={ 6} style={{maxHeight: 250}}>
                        <div className={css.imgContainer}>
                          <img src={images[2].img} alt={images[2].alt}
                            className={css.imgGallery}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={ 6} style={{maxHeight: 250}}>
                        <div className={css.imgContainer}>
                          <img src={images[3].img} alt={images[3].alt}
                            className={css.imgGallery}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={ 6} style={{maxHeight: 250}}>
                        <div className={css.imgContainer}>
                          <img src={images[4].img} alt={images[4].alt}
                            className={css.imgGallery}
                          />
                        </div>
                      </Grid>
                      </>
                      : null
                    }

                  </Grid>
                </Grid>

              </Grid>
            ):
            <Grid container spacing={1}>
                <Grid item xs={12} style={{maxHeight: 500}}>
                  <div className={css.imgContainer}>
                    <img src={images.length > 0 ? images[0].img : DefaultImage} alt={images.length > 0 ? images[0].alt : "No image"}
                      className={css.imgGallery}
                      style={{objectFit: 'none'}}
                    />
                  </div>
                </Grid>
            </Grid>
          }
          {viewPhotosButton}
        </div>
        <div className={css.mobileView}>
        <Grid container spacing={1}>
            <Grid item xs={12} style={{maxHeight: 500}}>
              <div className={css.imgContainer}>
                <img src={images.length > 0 ? images[0].img : DefaultImage} alt={images.length > 0 ? images[0].alt : "No image"}
                  className={css.imgGallery}
                  style={{objectFit: 'contain'}}
                />
              </div>
            </Grid>
            {viewPhotosButton}
        </Grid>
      </div>
      </div>

      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <ImageCarousel images={listing.images} />
      </Modal>
    </div>
  );
};

export default SectionImages;

{/* <Gallery
photos={images(listing)}
targetRowHeight={350}
limitNodeSearch={3}/> */}
