import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { UserCard, Modal, AvatarMedium, NamedLink } from '../../components';
import { EnquiryForm } from '../../forms';
import {
  createSlug,
} from '../../util/urlHelpers';
import css from './SectionRulesMaybe.module.css';

const SectionHostMaybe = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isPendingApprovalVariant,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    currentUser,
    isOwnListing,
    onManageDisableScrolling,
  } = props;

  if (!listing.author) {
    return null;
  }

  const slug = createSlug(listing.attributes.title);

  return (
    <div>


      <div className={css.sectionHost}>
        <AvatarMedium className={css.avatar} user={listing.author} />
        <div className={css.sectionHostProfil}>
          <p>{listing.author.attributes.profile.displayName} ({(listing.author.attributes.profile.publicData && listing.author.attributes.profile.publicData && listing.author.attributes.profile.publicData.isProfessional == true) ? "Professionel" : "Particulier"})</p>
          <NamedLink name="ProfilePage" params={{ id: listing.author.id.uuid }}>
            <FormattedMessage id="EditListingPage.seeProfile" />
          </NamedLink>
        </div>
      </div>
      {
        isPendingApprovalVariant &&
        <div className={css.pendingApproval}>
          <p><FormattedMessage id="EditListingPage.pendingAd" /></p>
        </div>
      }
      {
        isOwnListing ? (
          <div className={css.sectionHostEditButton}>
            <NamedLink
              name="EditListingPage"
              params={{
                id: listing.id.uuid,
                slug: slug,
                type: "edit",
                tab: 'description',
                category: listing.attributes.publicData.category
              }}
            >
              <FormattedMessage id="EditListingPage.titleEditListing" />
            </NamedLink>
          </div>
        )
          : null
      }


      <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>
    </div>
  );
};

export default SectionHostMaybe;
