import React, { Component, useEffect } from 'react';
import { number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Map } from '../../components';
import config from '../../config';
import axios from 'axios'

import css from './ListingPage.module.css';
import { ControlCameraSharp } from '@material-ui/icons';
import { toInteger } from 'lodash';

class SectionWeatherMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true, weatherWidget: "" };

  }

  componentDidMount() {
    const { className, rootClassName, geolocation } = this.props;

    axios.get('https://api-adresse.data.gouv.fr/reverse/?lat=' + geolocation.lat + "&lon=" + geolocation.lng)
      .then(response => {
        if (response.data && response.data.features[0]) {
          //console.log(response.data.features[0]);
          const cityCode = response.data.features[0].properties.citycode;
          this.setState({
            //weatherWidget: '<iframe id="widget_autocomplete_preview" width="150" height="300" frameBorder="0" src="https://meteofrance.com/widget/prevision/' + cityCode + '"> </iframe>'
            weatherWidget: cityCode + (cityCode.length < 6 ? "0" : "")
          });


        }
      })
      .catch(error => {
        console.log("API ADDRESS RESPONSE ERROR");
        console.log(error);
      });

  }



  /*
  curl "https://api-adresse.data.gouv.fr/search/?q=8+bd+du+port&lat=49.41889&lon=0.23306"
  
  => insee
  
  features[0].properties.citycode
  */

  render() {
    const { className, rootClassName, geolocation, width, height } = this.props;

    if (!geolocation) {
      return null;
    }


    const classes = classNames(rootClassName || css.sectionMap, className);
    const weatherWidget = this.state.weatherWidget;
    const url = "https://meteofrance.com/widget/prevision/" + weatherWidget;
    console.log("URL METEO ", url);
    /*<div className="tac_meteofrance" data-insee={weatherWidget} width={width} height={height}></div>*/
    /*<iframe id="widget_autocomplete_preview"  width={width} height={height} frameBorder="0" src={url}> </iframe>*/
    return (weatherWidget &&
      <div className={classes}>
        <h2 className={css.locationTitle}>
          <FormattedMessage id="ListingPage.meteoTitle" />
        </h2>
        <iframe id="widget_autocomplete_preview" width={width} height={height} frameBorder="0" src={url}> </iframe>

      </div>
    );
  }
}

SectionWeatherMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  width: 320,
  height: 250
};

SectionWeatherMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  width: number,
  height: number
};

export default SectionWeatherMaybe;
