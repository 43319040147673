import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import IconCheck from '../../assets/icons/annonces/check.svg'
import config from '../../config';
import { formatMoney } from '../../util/currency';


import css from './SectionRulesMaybe.module.css';

const depositData = (price) => {
  if (price && price.amount)
    return price.amount / 100 + " €"
  else
    return null
}

const SectionFeaturesMaybe = props => {
  const { publicData } = props;
  if (!publicData) {
    return null;
  }

  if (!publicData.amenities_garden_rent
    && !publicData.amenities_garden_share
    && !publicData.amenities_tinyhouse) {
    return null;
  }

  const imagesIcon = <img src={IconCheck} className={css.sectionFeatureIconCheck} alt="icon-check" />
  const formattedDeposit = depositData(publicData.deposit);
  return (
    <div className={css.sectionFeatures}>
      <h2 className={css.sectionSubTitle}>
        <FormattedMessage id="ListingPage.featuresTitle" />
      </h2>
      <div className={css.sectionFeaturesWrapper}>
        {
          publicData.amenities_garden_rent &&
          publicData.amenities_garden_rent.map((item, i) => {
            return <p key={i}>{imagesIcon}<FormattedMessage id={`FieldCheckbox.${[item]}`} /></p>
          })
        }
        {
          publicData.amenities_garden_share &&
          publicData.amenities_garden_share.map((item, i) => {
            return <p key={i}>{imagesIcon}<FormattedMessage id={`FieldCheckbox.${[item]}`} /></p>
          })
        }
        {
          publicData.amenities_tinyhouse &&
          publicData.amenities_tinyhouse.map((item, i) => {
            return <p key={i}>{imagesIcon}<FormattedMessage id={`FieldCheckbox.${[item]}`} /></p>
          })
        }
      </div>

      {publicData.games && publicData.games.length > 0 && <h2 className={css.sectionSubTitle}>Aire de jeux</h2>}
      <div className={css.sectionFeaturesWrapper}>

        {
          publicData.games &&
          publicData.games.map((item, i) => {
            return <p key={i}>{imagesIcon}<FormattedMessage id={`FieldCheckbox.${[item]}`} /></p>
          })
        }
      </div>
      {publicData.events && publicData.events.length > 0 && <h2 className={css.sectionSubTitle}>Events</h2>}
      <div className={css.sectionFeaturesWrapper}>
        {
          publicData.events &&
          publicData.events.map((item, i) => {
            return <p key={i}>{imagesIcon}<FormattedMessage id={`FieldCheckbox.${[item]}`} /></p>
          })
        }
      </div>
      {publicData.category !== 'garden_share' && publicData.ambiances && publicData.ambiances.length > 0 && <h2 className={css.sectionSubTitle}>Ambiance</h2>}
      {publicData.category !== 'garden_share' &&
        < div className={css.sectionFeaturesWrapper}>
          {
            publicData.ambiances &&
            publicData.ambiances.map((item, i) => {
              return <p key={i}>{imagesIcon}<FormattedMessage id={`FieldCheckbox.${[item]}`} /></p>
            })
          }
        </div>
      }

      {
        publicData.parking && <h2 className={css.sectionSubTitle}>Stationement</h2>
      }
      <div className={css.sectionFeaturesWrapper}>
        {
          publicData.parking &&
          publicData.parking.map((item, i) => {
            return <p key={i}>{imagesIcon}<FormattedMessage id={`FieldCheckbox.${[item]}`} /></p>
          })
        }
      </div>
      {
        publicData.water_point &&
        <>
          <h2 className={css.sectionSubTitle}>Point d'eau</h2>
          <div className={css.sectionFeaturesWrapper}>
            <p>{imagesIcon}<FormattedMessage id={`FieldCheckbox.${[publicData.water_point]}`} /></p>
          </div>
        </>
      }
      {
        publicData.night_light &&
        <>
          <h2 className={css.sectionSubTitle}>Eclairage nocturne</h2>
          <div className={css.sectionFeaturesWrapper}>
            <p>{imagesIcon}<FormattedMessage id={`FieldCheckbox.${[publicData.night_light]}`} /></p>
          </div>
        </>
      }

      <div className={css.infoComp}>
        <h3 className={css.sectionCaption}>Information complementaires</h3>
        {
          publicData.capacity_access &&
          <p>
            <FormattedMessage id={`EditListingCapacityForm.capacityAccessLabel`} /> :&nbsp;
            <FormattedMessage id={`FieldCheckbox.${[publicData.capacity_access]}`} />
          </p>
        }
        {
          formattedDeposit &&
          <p>
            <FormattedMessage id={`EditListingPricingForm.labelDeposit`} /> :&nbsp;
            {formattedDeposit}
          </p>
        }
        {
          publicData.price_terms &&
          <p>
            <FormattedMessage id={`EditListingPricingForm.labelTerms`} /> :&nbsp;
            <FormattedMessage id={`FieldCheckbox.${[publicData.price_terms]}`} />
          </p>
        }


      </div>
    </div>
  );
};

export default SectionFeaturesMaybe;
